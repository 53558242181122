import { useQuery, UseQueryOptions } from 'react-query'

import Api from '~/services/Api'
import Deserializer from '~/services/Deserializer'

export const staticPageConfig = {
  allStaticPages: {
    key: 'posts/allStaticPages',
    request: async () => {
      const resp = await Api.getAllStaticPages()
      const data = await Deserializer.deserialize(resp)

      return data
    }
  },
  getStaticPage: {
    key: 'posts/getStaticPage',
    request: async ({ queryKey }) => {
      const [_key, params] = queryKey

      const resp = await Api.getStaticPageBySlug(params)
      const data = await Deserializer.deserialize(resp)

      return data
    }
  }
}

interface UseGetAllStaticPagesQuery {
  options?: UseQueryOptions<StaticPages[]>
}

export const useGetAllStaticPagesQuery = (
  props?: UseGetAllStaticPagesQuery
) => {
  const { allStaticPages: config } = staticPageConfig
  const { options } = props || {}

  const state = useQuery<StaticPages[]>([config.key], config.request, {
    ...options
  })

  return state
}

interface UseGetStaticPageQueryProps {
  slug: string
  options?: UseQueryOptions<StaticPage>
}

export const useGetStaticPageQuery = (props?: UseGetStaticPageQueryProps) => {
  const { getStaticPage: config } = staticPageConfig
  const { slug, options } = props || {}

  const state = useQuery<StaticPage>([config.key, slug], config.request, {
    enabled: !!slug,
    ...options
  })

  return state
}
