import React, { FC } from 'react'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Link from '~/components/base/Link'
import Logo from '~/components/base/Logo'

import { useGetAllStaticPagesQuery } from '~/services/Query/static-pages/static-pages.query'
import ROUTES from '~/utils/constants/routes'

import SocialLinks from './components/SocialLinks'
import msg from './Footer.messages'

const Footer: FC = () => {
  const { data } = useGetAllStaticPagesQuery()

  const excludeHeaderLink = ['Header', 'Hidden']

  const footerLinks = data?.filter(
    (item) => !excludeHeaderLink.includes(item.name)
  )

  return (
    <Box
      sx={{
        width: 'calc(100% - 30px)',
        minHeight: 210,
        margin: '15px',
        py: 3,
        backgroundColor: 'background.grey',
        borderRadius: '40px'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} order={{ xs: 2, md: 0 }}>
            <Logo />

            <Typography
              variant="subtitle2"
              color="text.headline"
              fontWeight={400}
              mt={0.5}
            >
              <FormattedMessage
                {...msg.rightsReserved}
                values={{
                  year: new Date().getFullYear(),
                  br: <br />
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 2, sm: 5 }}
              mt={{ xs: 0, md: 0.5 }}
              justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            >
              {footerLinks?.map((item) => {
                const isBakingBusiness =
                  item?.name?.toUpperCase() === 'BAKING BUSINESS'
                const isSupport = item?.name?.toUpperCase() === 'SUPPORT'

                return (
                  <Stack key={item.id} width="100%">
                    <Typography
                      variant="h4"
                      color="primary"
                      fontSize={16}
                      fontWeight={700}
                      mb={1.5}
                    >
                      {item.name}
                    </Typography>

                    {item?.static_pages?.map(({ slug, title }) => {
                      return (
                        <Link
                          key={slug}
                          href={`/${slug}`}
                          color="textSecondary"
                          fontSize={14}
                        >
                          {title}
                        </Link>
                      )
                    })}

                    {isSupport && (
                      <Link href="/contact" color="textSecondary" fontSize={14}>
                        <FormattedMessage {...msg.contactUs} />
                      </Link>
                    )}

                    {isBakingBusiness && (
                      <Link
                        href={ROUTES.calculator}
                        color="textSecondary"
                        fontSize={14}
                      >
                        <FormattedMessage {...msg.calculator} />
                      </Link>
                    )}
                  </Stack>
                )
              })}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            mt={{ xs: 0, md: 0.5 }}
          >
            <Stack>
              <Typography
                variant="h4"
                color="primary"
                fontSize={16}
                fontWeight={700}
                mb={1.7}
              >
                <FormattedMessage {...msg.socials} />
              </Typography>
              <SocialLinks />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
