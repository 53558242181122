import { useIntl } from 'react-intl'

import Facebook from '~/assets/icons/social/facebook.svg'
import Instagram from '~/assets/icons/social/instagram.svg'

import msg from '../../Footer.messages'

interface SocialLinksProps {
  id: number
  icon: JSX.Element
  href: string
  text: string
}

export const useSocialLinks = (): SocialLinksProps[] => {
  const { formatMessage } = useIntl()

  const socialLinks = [
    {
      id: 1,
      icon: <Facebook />,
      href: 'https://www.facebook.com/cakerhq',
      text: formatMessage(msg.facebook)
    },
    {
      id: 2,
      icon: <Instagram />,
      href: 'https://www.instagram.com/cakerhq/',
      text: formatMessage(msg.instagram)
    }
  ]
  return socialLinks
}
