import { defineMessages } from 'react-intl'

const msg = defineMessages({
  rightsReserved: {
    defaultMessage: 'Copiright © {year} Cakerhq. {br} All rights are reserved.'
  },
  socials: {
    defaultMessage: 'Find our socials'
  },
  facebook: {
    defaultMessage: 'Facebook'
  },
  instagram: {
    defaultMessage: 'Instagram'
  },
  contactUs: {
    defaultMessage: 'Contact Us'
  },
  calculator: {
    defaultMessage: 'Calculator'
  }
})

export default msg
