import React, { FC } from 'react'

import { IconButton, Link, Stack } from '@mui/material'

import Text from '~/components/base/Text'

import { useSocialLinks } from './SocialLinks.config'

const SocialLinks: FC = () => {
  const socialLinks = useSocialLinks()

  return (
    <Stack direction="column">
      {socialLinks.map(({ id, icon, href, text }) => (
        <Stack flexDirection="row" key={id}>
          <IconButton sx={{ height: 'auto', width: 18 }} aria-label={text}>
            <Link href={href} target="_blank" aria-label={text}>
              {icon}
            </Link>
          </IconButton>

          <Link
            href={href}
            target="_blank"
            sx={{ alignSelf: 'center' }}
            aria-label={text}
          >
            <Text size={{ xs: 'subtitle1' }} ml={0.5} color="text.secondary">
              {text}
            </Text>
          </Link>
        </Stack>
      ))}
    </Stack>
  )
}

export default SocialLinks
